import axios from '@/http/axios'

export function getTreasuryRequirements() {
    return axios({
        url: 'v1/admin/requirements/treasury',
        method: 'get'
    })
}

export function getTimeRequirements() {
    return axios({
        url: 'v1/admin/requirements/time',
        method: 'get'
    })
}